@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

* {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
    color: var(--color-black);
}

*:before,
*:after {
    box-sizing: border-box;
}

:focus,
:active {
    outline: none;
}

a:focus,
a:active {
    outline: none;
}

nav,
footer,
header,
aside {
    display: block;
}

html,
body {
    height: 100%;
    width: 100%;
    line-height: 1;
    font-size: 14px;
    font-family: var(--font-main);
    background: var(--color-white);
    font-weight: 400;
}

input,
button,
textarea {
    font-family: inherit;
}

input::-ms-clear {
    display: none;
}

button {
    cursor: pointer;
    outline: none;
    background: transparent;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

a {
    text-decoration: none;
}

ul,
li {
    list-style: none;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: 400;
}

:root {
    --radius: 8px;
    --radius-second: 5px;
    --shadow: 0px 12px 24px rgba(48, 94, 144, 0.2);
    --transition: 0.2s ease;
    --color-white: #ffffff;
    --color-blue: #5893d9;
    --color-darkblue: #305e90;
    --color-lightblue: #d1e6ff;
    --color-black: #333333;
    --color-lightblack: #4c4c4c;
    --color-input: #d7d7d7;
    --color-input-placeholder: #75758b;
    --font-main: 'Roboto', sans-serif;
    --font-secondary: 'Open Sans', serif;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

body {
    animation: fade 0.5s linear;
    min-width: 320px;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

html {
    scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}
.App {
    position: relative;
}
